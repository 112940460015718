import React from 'react';

const AuthModal = ({ onClose }) => {
  return (
    <div
      className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 backdrop-blur-sm z-50"
      onClick={onClose}
    >
      <div
        className="relative w-full max-w-md p-8 bg-white bg-opacity-90 rounded-lg shadow-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-0 right-2 text-gray-600 hover:text-gray-800 text-2xl"
        >
          &times;
        </button>

        <h2 className="text-3xl font-bold text-black text-center mb-6">Welcome!</h2>

        <button
          className="w-full px-4 py-2 bg-black text-white text-lg rounded-md hover:bg-gray-800 transition duration-300"
          onClick={() => window.location.href = '/login'}
        >
          Log In
        </button>

        <p className="mt-4 text-center text-gray-700">
          Don’t have an account?{' '}
          <a
            href="/register"
            className="text-blue-600 hover:text-blue-800 font-semibold"
          >
            Sign up!
          </a>
        </p>
      </div>
    </div>
  );
};

export default AuthModal;
