import React, { useState } from 'react';
import backgroundImage from './images/pexels-rpnickson.jpg';
import AuthModal from './AuthModal';

const HeroSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <section
      id="hero"
      className="relative h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="relative z-10 flex flex-col justify-center items-center text-center text-white h-full bg-black bg-opacity-30">
        <h1 className="text-6xl font-bold mb-4">Urban Tectonic</h1>
        <p className="text-2xl font-light mb-8">Your Vision, Our Blueprint</p>

        <button
          onClick={openModal}
          className="px-4 py-2 border border-white text-white rounded-md hover:bg-white hover:text-black transition duration-300"
        >
          Get Started
        </button>

        {isModalOpen && <AuthModal onClose={closeModal} />}
      </div>
    </section>
  );
};

export default HeroSection;
