import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Results = () => {
  const contractors = JSON.parse(localStorage.getItem('contractors') || '[]');

  console.log("Contractors Array:", contractors);

  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-100 py-10">
        <div className="w-full max-w-4xl mx-auto p-8 bg-white shadow-lg rounded-lg">
          <h2 className="text-3xl font-bold mb-6">Search Results</h2>
          {Array.isArray(contractors) && contractors.length > 0 ? (
            <ul className="space-y-4">
              {contractors.map((contractor, index) => (
                <li key={contractor.ID || index} className="p-4 border rounded-md">
                    <p className="font-bold">{contractor.BusinessName}</p>
                    <p>{contractor.City}, {contractor.State}</p>
                    <p>{contractor.BusinessPhone}</p>
                </li>
                ))}
            </ul>
          ) : (
            <p>No contractors found.</p>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Results;
