const classifications = [
    { code: "C2", description: "Insulation and Acoustical Contractor" },
    { code: "C4", description: "Boiler, Hot Water Heating and Steam Fitting Contractor" },
    { code: "C5", description: "Framing and Rough Carpentry Contractor" },
    { code: "C6", description: "Cabinet, Millwork and Finish Carpentry Contractor" },
    { code: "C7", description: "Low Voltage Systems Contractor" },
    { code: "C8", description: "Concrete Contractor" },
    { code: "C9", description: "Drywall Contractor" },
    { code: "C10", description: "Electrical Contractor" },
    { code: "C11", description: "Elevator Contractor" },
    { code: "C12", description: "Earthwork and Paving Contractors" },
    { code: "C13", description: "Fencing Contractor" },
    { code: "C15", description: "Flooring and Floor Covering Contractors" },
    { code: "C16", description: "Fire Protection Contractor" },
    { code: "C17", description: "Glazing Contractor" },
    { code: "C20", description: "Warm-Air Heating, Ventilating and Air-Conditioning Contractor" },
    { code: "C21", description: "Building Moving/Demolition Contractor" },
    { code: "C22", description: "Asbestos Abatement Contractor" },
    { code: "C23", description: "Ornamental Metal Contractor" },
    { code: "C27", description: "Landscaping Contractor" },
    { code: "C28", description: "Lock and Security Equipment Contractor" },
    { code: "C29", description: "Masonry Contractor" },
    { code: "C31", description: "Construction Zone Traffic Control Contractor" },
    { code: "C32", description: "Parking and Highway Improvement Contractor" },
    { code: "C33", description: "Painting and Decorating Contractor" },
    { code: "C34", description: "Pipeline Contractor" },
    { code: "C35", description: "Lathing and Plastering Contractor" },
    { code: "C36", description: "Plumbing Contractor" },
    { code: "C38", description: "Refrigeration Contractor" },
    { code: "C39", description: "Roofing Contractor" },
    { code: "C42", description: "Sanitation System Contractor" },
    { code: "C43", description: "Sheet Metal Contractor" },
    { code: "C45", description: "Sign Contractor" },
    { code: "C46", description: "Solar Contractor" },
    { code: "C47", description: "General Manufactured Housing Contractor" },
    { code: "C49", description: "Tree and Palm Contractor" },
    { code: "C50", description: "Reinforcing Steel Contractor" },
    { code: "C51", description: "Structural Steel Contractor" },
    { code: "C53", description: "Swimming Pool Contractor" },
    { code: "C54", description: "Ceramic and Mosaic Tile Contractor" },
    { code: "C55", description: "Water Conditioning Contractor" },
    { code: "C57", description: "Well Drilling Contractor" },
    { code: "C60", description: "Welding Contractor" },
    { code: "C61", description: "Limited Specialty" },
  ];

  export default classifications;
